import { TouchableOpacity } from 'react-native';

const IconButton = ({ onPress, style, Icon }) => (
  <TouchableOpacity
    style={[{ cursor: 'pointer' }, style]}
    {...{ onPress }}
  >
    <Icon />
  </TouchableOpacity>
);

export default IconButton;
