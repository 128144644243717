import { Modal, View, ScrollView } from 'react-native';
import Text from 'components/Text';
import IconButton from 'components/IconButton';
import makeStyles from 'styles/makeStyles';
import CloseIcon from 'assets/close_black_24dp.svg';

const styles = makeStyles((theme) => ({
  shadow: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
  },
  modalView: {
    // overflow: hidden,// Don't add HIDDEN. Dropdown must overlap dialog
    margin: theme.spacing(5),
    backgroundColor: 'white',
    borderRadius: theme.borderRadiusBase,
    overflow: 'hidden',
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.spacing(4),
    paddingHorizontal: theme.spacing(6),
    backgroundColor: 'rgba(128,144,163,0.25)',
  },
  headerText: {
    flex: 1,
    fontWeight: 500,
    fontSize: theme.fontSizeLg,
    marginVertical: theme.spacing(2),
    color: 'rgba(0, 0, 0, .85)',
  },
  content: {
    paddingVertical: theme.spacing(6),
    paddingHorizontal: theme.spacing(6),
    zIndex: 3,
    elevation: 3,
  },
  actions: {
    ...theme.borderTop(1, 'solid', theme.borderColorBase),
    // ...theme.borderTop(1, 'solid', '#E8E8E8'),
    paddingVertical: theme.spacing(4),
    paddingHorizontal: theme.spacing(6),
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const Dialog = ({
  actions,
  children,
  headerStyle,
  headerTextStyle,
  onRequestClose,
  style = {},
  contentStyle = {},
  title,
  visible = false,
}) => {
  const closeButton = (
    <IconButton Icon={CloseIcon} onPress={onRequestClose} />
  );
  return (
    <Modal
      // animationType="slide"
      transparent
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <ScrollView>
        <View style={styles.shadow}>
          <View style={[styles.modalView, style]}>
            <View style={[styles.header, headerStyle || {}]}>
              <Text style={[styles.headerText, headerTextStyle || {}]}>{title}</Text>
              {closeButton}
            </View>
            <View style={[styles.content, contentStyle]}>
              {children}
            </View>
            <View style={styles.actions}>
              {actions}
            </View>
          </View>
        </View>
      </ScrollView>
    </Modal>
  );
};

export default Dialog;
