import Dialog from 'components/Dialog';
import Button from 'components/Button';
import Text from 'components/Text';

const NewVersionDialog = ({ visible, onClose, onRefresh }) => {
  const handleRefresh = () => {
    onRefresh();
    onClose();
  };

  return (
    <Dialog
      visible={visible}
      title="New version is available"
      onRequestClose={onClose}
      actions={[
        <Button key="cancel" onPress={onClose} variant="cancel">Cancel</Button>,
        <Button key="refresh" onPress={handleRefresh} variant="primary">Refresh</Button>,
      ]}
    >
      <Text>Do you want to refresh current version?</Text>
    </Dialog>
  );
};

export default NewVersionDialog;
